<template>
  <v-alert
    outlined
    type="error"
    text
    prominent
    border="left"
    icon="mdi-cloud-alert"
    class="wrapText"
  >
    <strong>{{ errorMessage }}</strong>
    <span v-html="errorValidateData">

    </span>
  </v-alert>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    errorMessage: String,
    errorValidateData: String,
  },
};
</script>

<style scoped>
.wrapText {
  width: 750;
  white-space: pre-line;
}
</style>
