<template>
  <div>
    <v-alert
      v-if="type === 'success'"
      dense
      outlined
      class="alert mx-4"
      border="left"
      type="success"
      transition="fade-transition"
    >
      {{ message }}
    </v-alert>
    <v-alert
      transition="scale-transition"
      dismissible
      outlined
      close-text="Close Alert"
      v-if="type === 'error'"
      class="alert transition-swing mx-4"
      border="left"
      color="hqsOrange"
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    message: String,
  },
}
</script>

<style scoped>
.alert {
  margin: auto;
}
</style>
